import React, { Component } from 'react';
import { Switch, Route,BrowserRouter } from 'react-router-dom';
import './common/assets/scss/style.scss';
import PrivateRoute from './auth_module/routing_module/PrivateRoute';
// import PublicRoute from './auth_module/routing_module/PublicRoute';
// import { Router } from 'react-router';
// import * as Sentry from "@sentry/react";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const Landing = React.lazy(()=> import('./landing_module/views/LandingPage'));
const About = React.lazy(()=> import('./landing_module/views/AboutPage'));
const AnonymizeIdentity = React.lazy(()=> import('./landing_module/views/AnonymizeIdentityPage'));

const PrivacyPolicy = React.lazy(()=> import('./landing_module/views/Privacy'));

const PortalLayout = React.lazy(() => import('./admin_console_module/component/ConsoleLayout'));

const DocumentationLayout = React.lazy(() => import('./documentation_module/containers/TheLayout'));

//AUTH PAGES
const SSASignIn = React.lazy(()=> import('./landing_module/views/SignIn'));
const SmsOtp = React.lazy(()=> import('./landing_module/views/SmsOtp'));

//RESULT PAGES
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Success = React.lazy(() => import('./views/pages/success/register'));
const OptSucess = React.lazy(() => import('./views/pages/success/optout'));

//PORTAL CONSOLE
const Dashboard = React.lazy(() => import('./admin_console_module/views/AdminConsole'));

const UserMgt = React.lazy(() => import('./user_management_module/UserMgt'));

const BenieAppReq = React.lazy(() => import('./benie_management_module/application_request_component/BenieMgt'));
const CurrBenie = React.lazy(() => import('./benie_management_module/current_benie_component/CurrBenieMgt'));
const BroadcastReq = React.lazy(() => import('./benie_management_module/broadcast_request_component/ReqMgt'));

const ReqRecords = React.lazy(() => import('./benie_management_module/broadcast_request_component/ReqRecords'));
const DonRecords = React.lazy(() => import('./benie_management_module/broadcast_request_component/DonRecords'));


const CurrDonor = React.lazy(() => import('./donor_management_module/current_donor_component/CurrDonorMgt'));
const DonorAppReq = React.lazy(() => import('./donor_management_module/pending_request_component/PendingReqMgt'));

const VolAppReq = React.lazy(() => import('./volunteer_management_module/volunteer_request_component/VolunteerReqMgt'));
const CurrVol = React.lazy(() => import('./volunteer_management_module/current_volunteer_component/CurrVolunteerMgt'));

const InvMgt = React.lazy(() => import('./inventory_management_module/InventoryMgt'));
// const InvRecon = React.lazy(() => import('./inventory_management_module/InventoryRecon'));

const CentreMgt = React.lazy(() => import('./centre_management_module/CentreMgt'));
const CollectionList = React.lazy(() => import('./centre_management_module/CollectionList'));
const Donations = React.lazy(() => import('./self_donation_module/DonationsMgt'));

const ProgrammeMgt = React.lazy(() => import('./anonymized_identity_module/programme_component/ProgrammeMgt'));
const MemberMgt =  React.lazy(() => import('./anonymized_identity_module/programme_component/MemberMgt'));
const MemberMgt2 =  React.lazy(() => import('./anonymized_identity_module/programme_component/MemberMgt2'));
const CashDonations = React.lazy(() => import('./anonymized_identity_module/programme_component/CashDonations'));
const CashDonations2 = React.lazy(() => import('./anonymized_identity_module/programme_component/CashDonations2'));
const BlockchainTransactions = React.lazy(() => import('./anonymized_identity_module/programme_component/BlockchainTransactions'));
const ProductDonations = React.lazy(() => import('./anonymized_identity_module/programme_component/ProductDonations'));
const ProductDonations2 = React.lazy(() => import('./anonymized_identity_module/programme_component/ProductDonations2'));
const ProductDonations3 = React.lazy(() => import('./anonymized_identity_module/programme_component/ProductDonations3'));
const Reimbursement = React.lazy(() => import('./anonymized_identity_module/programme_component/Reimbursement'));
const Reimbursement2 = React.lazy(() => import('./anonymized_identity_module/programme_component/Reimbursement2'));
const Reimbursement3 = React.lazy(() => import('./anonymized_identity_module/programme_component/Reimbursement3'));
const Reimbursement4 = React.lazy(() => import('./anonymized_identity_module/programme_component/Reimbursement4'));
const Reimbursement5 = React.lazy(() => import('./anonymized_identity_module/programme_component/Reimbursement5'));
const Settings = React.lazy(() => import('./settings_module/Settings'));

//  function FallbackComponent() {
//     console.log("An error has occured.");
//     return <></>;
//   }
//   const myFallback = <FallbackComponent />;
//DEVELOPERS CONSOLE
class App extends Component {
 
  render() {
    document.title = "PayEatFwd";
    return (
      // <Sentry.ErrorBoundary fallback={myFallback}>
      <BrowserRouter>
      <React.Suspense fallback={loading}>
            <Switch>
             <Route exact path="/" name="Landing Page" component={props => <Landing {...props}/>} />
              <Route path="/documentation/landing" name="Open API" component={props => <DocumentationLayout {...props}/>} /> 
              {/* <Route exact path="/documentation/landing" name="Documentation" component={props => <DocumentationModule {...props}/>} /> */}
            <Route exact path="/about" name="About" component={props => <About {...props} />} />
            <Route exact path="/donations" name="Anon Identity" component={props => <AnonymizeIdentity {...props} />} />
            <Route exact path="/privacy" name="Privacy Policy" component={props => <PrivacyPolicy {...props} />} />
          

              {/** AUTH ROUTES*/}
              {/*<PublicRoute restricted = {false} exact path="/portal/login" name="Login Page" component={props => <Login {...props}/>} />*/}
              <Route exact path="/portal/signin" name="Portal Sign In" component={props => <SSASignIn {...props}/>} />
              <Route exact path="/portal/otp" name="Portal Sms Otp" component={props => <SmsOtp {...props}/>} />

              {/* <Route restricted = {false} exact path="/portal/otp" name="Otp Page" component={props => <Otp {...props}/>} /> */}
              {/* <Route exact path="/signup" name="Sign Up" component={props => <SSASignUP {...props}/>} /> */}
              {/* <Route exact path="/dev/signup" name="Developer Sign Up" component={props => <DevSignUp {...props}/>} />
              <Route exact path="/developer/signin" name="Developer Sign In" component={props => <DevSignIn {...props}/>} /> */}
              
              {/** STATUS HANDLING*/}
              <Route exact path="/portal/500" name="Page 500" component={props => <Page500 {...props}/>} />
              {/* <PublicRoute restricted = {false} exact path="/portal/404" name="Page 404" component={props => <Page404 {...props}/>} /> */}
              <Route exact path="/registerSuccess/:code" name="Success" component={props => <Success {...props}/>} />
              <Route exact path="/optSuccess" name="Success" component={props => <OptSucess {...props}/>} />
                             
              {/*PORTAL CONSOLE*/}               

              <Route path="/portal" name="Layout" component={props => <PortalLayout {...props}/>} />
            <PrivateRoute exact path="/portal/dashboard" name="Dashboard" component={props => <Dashboard {...props}/>} />
              <PrivateRoute exact path="/portal/usermanagement" name="Admin Users" component={props => <UserMgt {...props}/>} />
              {/* <PrivateRoute exact path="/portal/beniemanagement" name="Benie Management" component={props => <Dashboard {...props}/>} /> */}
              <PrivateRoute exact path="/portal/beniemanagement/applicationrequest" name="Application Requests" component={props => <BenieAppReq {...props}/>} />
              <PrivateRoute exact path="/portal/beniemanagement/currentbenies" name="Current Status" component={props => <CurrBenie {...props}/>} />
              <PrivateRoute exact path="/portal/beniemanagement/broadcastedrequests" name="Broadcast Requests" component={props => <BroadcastReq {...props}/>} />

              {/* <PrivateRoute exact path="/portal/beniemanagement/broadcastedrequests/pendingrequests" name="Pending Requests" component={props => <BroadcastReq {...props}/>} /> */}
              {/* <PrivateRoute exact path="/portal/beniemanagement/broadcastedrequests/consolidaterequests" name="Consolidate Requests" component={props => <ConsolidateReq {...props}/>} /> */}
              {/* <PrivateRoute exact path="/portal/beniemanagement/broadcastedrequests/directrequests" name="Direct Requests" component={props => <DirectReq {...props}/>} /> */}
              <PrivateRoute exact path="/portal/beniemanagement/broadcastedrequests/requested/:consolidateid" name="Consolidate Mode Requested Records" component={props => <ReqRecords {...props}/>} />
              <PrivateRoute exact path="/portal/beniemanagement/broadcastedrequests/donated/:consolidateid" name="Consolidate Mode Donated Records" component={props => <DonRecords {...props}/>} />

              {/* <PrivateRoute exact path="/portal/donormanagement" name="Donor Management" component={props => <Dashboard {...props}/>} /> */}
              <PrivateRoute exact path="/portal/donormanagement/currentdonors" name="Current Status" component={props => <CurrDonor {...props}/>} />
              <PrivateRoute exact path="/portal/donormanagement/donorrequest" name="Application Requests" component={props => <DonorAppReq {...props}/>} />
              {/* <PrivateRoute exact path="/portal/volunteermanagement" name="Volunteer Management" component={props => <Dashboard {...props}/>} /> */}
              <PrivateRoute exact path="/portal/volunteermanagement/volunteerrequest" name="Application Requests" component={props => <VolAppReq {...props}/>} />
              <PrivateRoute exact path="/portal/volunteermanagement/currentvolunteers" name="Current Status" component={props => <CurrVol {...props}/>} />
              <PrivateRoute exact path="/portal/inventorymanagement" name="Food Item Management" component={props => <InvMgt {...props}/>} />

              <PrivateRoute exact path="/portal/centremanagement" name="Collection Centres" component={props => <CentreMgt {...props}/>} />
              <PrivateRoute exact path="/portal/centremanagement/collectionlist/:ccid" name="Collection List" component={props => <CollectionList {...props}/>} />

              <PrivateRoute exact path="/portal/donations/selfcollectdonations" name="Donations List" component={props => <Donations {...props}/>} />

              <PrivateRoute exact path="/portal/settings" name="Settings" component={props => <Settings {...props}/>} />
              <PrivateRoute exact path="/portal/programmemanagement" name="Programme Management" component={props => <ProgrammeMgt {...props}/>} />
              <PrivateRoute exact path="/portal/membermanagement" name="Member Management" component={props => <MemberMgt {...props}/>} />
              <PrivateRoute exact path="/portal/membermanagement/:programmeId" name="Member Management 2" component={props => <MemberMgt2 {...props}/>} />
              <PrivateRoute exact path="/portal/blockchaintransactions" name="Blockchain Transactions" component={props => <BlockchainTransactions {...props}/>} />
              <PrivateRoute exact path="/portal/cashdonations" name="Cash Donations" component={props => <CashDonations {...props}/>} />
              <PrivateRoute exact path="/portal/cashdonations/:programmeId" name="Cash Donations" component={props => <CashDonations2 {...props}/>} />
              <PrivateRoute exact path="/portal/productdonations" name="Cash Donations" component={props => <ProductDonations {...props}/>} />
              <PrivateRoute exact path="/portal/productdonations/:programmeId" name="Cash Donations" component={props => <ProductDonations2 {...props}/>} />
              <PrivateRoute exact path="/portal/productdonations/:programmeId/:locationName" name="Cash Donations" component={props => <ProductDonations3 {...props}/>} />
              <PrivateRoute exact path="/portal/reimbursement" name="Reimbursement" component={props => <Reimbursement {...props}/>} />
              <PrivateRoute exact path="/portal/reimbursement/:programmeId" name="Reimbursement" component={props => <Reimbursement2 {...props}/>} />
              <PrivateRoute exact path="/portal/reimbursement/:programmeId/:locationName" name="Reimbursement" component={props => <Reimbursement3 {...props}/>} />
              <PrivateRoute exact path="/portal/reimbursement/:programmeId/:locationName/:merchantId/transaction" name="Reimbursement" component={props => <Reimbursement4 {...props}/>} />
              <PrivateRoute exact path="/portal/reimbursement/:programmeId/:locationName/:merchantId/transaction/:orderId" name="Reimbursement" component={props => <Reimbursement5 {...props}/>} />
            </Switch>
          </React.Suspense>
     </BrowserRouter>
    //  </Sentry.ErrorBoundary>
    );
  }
}

export default App;
